import React from 'react';
import { DateInput, TextInput, NumberInput, ReferenceInput, AutocompleteInput, FormDataConsumer, required } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import CurrencyInput from '../common/inputs/CurrencyInput';

const optionText = choice => choice.name ? choice.name : '';

const DataPoolEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="reference" />
            <TextInput source="customer_reference" />
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Billing Address" source="invoicing_address_id" reference="addresses" filter={formData.customer_id && { customer_id: formData.customer_id }} validate={required()} {...rest} >
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <ReferenceInput sort={{ field: 'name', order: 'ASC' }} label="Billing company" source="billing_company_id" reference="companies" allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <NumberInput source="data_included" />
            <NumberInput source="data_price" />
            <NumberInput source="data_extra_price" />
            <CurrencyInput source="currency" />
            <TextInput label="Data Limit Contacts (CSV)" source="data_limit_contacts" options={{ multiline: true }} rows="4" />
            <DateInput source="start_date" />
            <DateInput source="end_date" />
        </SimpleForm>
    </Edit>
);

export default DataPoolEdit;