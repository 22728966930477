import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceManyField, Pagination } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';
import StarlinkAddressRow from '../starlink_addresses/StarlinkAddressRow';
import StarlinkServiceLineRow from '../starlink_service_lines/StarlinkServiceLineRow';
import StarlinkTerminalRow from '../starlink_terminals/StarlinkTerminalRow';


const StarlinkAccountTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Starlink account[${record.id}]`}</title>
        </Helmet>
        <span>{record.id}</span>
    </>
);

const StarlinkAccountShow = (props) => (
    <Show title={<StarlinkAccountTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="account_name" />
                <TextField source="account_number" />
                <TextField source="region_code" />
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
            </Tab>
            <Tab label="Addresses" path="starlink_addresses">
                <ReferenceManyField addLabel={false} reference="starlink_addresses" target="account_id" pagination={<Pagination />}>
                    <StarlinkAddressRow />
                </ReferenceManyField>
            </Tab>
            <Tab label="Service lines" path="starlink_service_lines">
                <ReferenceManyField addLabel={false} reference="starlink_service_lines" target="account_id" pagination={<Pagination />}>
                    <StarlinkServiceLineRow />
                </ReferenceManyField>
            </Tab>
            <Tab label="Terminals" path="starlink_terminals">
                <ReferenceManyField addLabel={false} reference="starlink_terminals" target="account_id" pagination={<Pagination />}>
                    <StarlinkTerminalRow />
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default StarlinkAccountShow;
