import React from 'react';
import { TextInput, DateInput, BooleanInput, NumberInput, ReferenceInput, AutocompleteInput, FormDataConsumer } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

const optionText = choice => choice && choice.reference ? choice.reference : '';


const PackageLineEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput
                        label="Product"
                        source="device_product_id"
                        reference="device_products"
                        filter={{ archived: false }}
                        sort={{ field: 'reference', order: 'ASC' }}
                        {...rest}
                    >
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <NumberInput source="qty" />
            <NumberInput source="price" />
            <TextInput source="notes" label="Notes" options={{ multiline: true }} rows="4" />
            <TextInput source="serials" label="Serials" options={{ multiline: true }} rows="4" />
            <TextInput source="shipping_tracking_code" />
            <DateInput source="shipping_date" />
            <DateInput source="delivery_date" />
            <BooleanInput source="is_free" label="Free of charge" />
        </SimpleForm>
    </Edit>
);

export default PackageLineEdit;