import React from 'react';
import { TextField, ReferenceField, ReferenceArrayField, NumberField, SimpleShowLayout, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import StarlinkTerminalRow from '../starlink_terminals/StarlinkTerminalRow';
import RecordSplitButton from '../common/RecordSplitButton';
import StarlinkServiceLineURLButton from './StarlinkServiceLineActionsButtons/StarlinkServiceLineURLButton';
import OptInOptOutButton from './StarlinkServiceLineActionsButtons/OptInOptOutButton';
import StarlinkSerciceLineSyncButton from './StarlinkServiceLineActionsButtons/StarlinkServiceLineSyncButton';
import PauseResumeButton from './StarlinkServiceLineActionsButtons/PauseResumeButton';


const StarlinkServiceLineSplitButton = ({ record, ...props }) => (
	<RecordSplitButton record={record} {...props} archiveButton={false} hasEdit={false}>
        <StarlinkServiceLineURLButton />
        <PauseResumeButton />
        <OptInOptOutButton />
        <StarlinkSerciceLineSyncButton />
	</RecordSplitButton>
);

const StarlinkServiceLineDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="id" />
        <TextField source="service_line_number" />
        <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
            <TextField source="account_number" />
        </ReferenceField>
        <ReferenceField label="Starlink address" source="starlink_address" reference="starlink_addresses" link="show" allowEmpty>
            <TextField source="formatted_address" />
        </ReferenceField>
        <NumberField source="current_usage" label="Current Usage(GB)" sortable={false}/>
        <BooleanField source="active" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <ReferenceArrayField addLabel={false} reference="starlink_terminals" source="starlink_terminals">
            <StarlinkTerminalRow />
        </ReferenceArrayField>
    </SimpleShowLayout>
);

const StarlinkServiceLineRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkServiceLineDrawer {...props} />} {...props}>
        <LinkFieldButton label="Service line number" sortBy="service_line_number">
            <TextField source="service_line_number" />
        </LinkFieldButton>
        <TextField source="account_number" />
        <TextField source="nickname" />
        <BooleanField source="is_opted_in" label="Opted In" />
        <BooleanField source="active" label="Active" />
        <TextField source="product_reference_id" label="Product" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <NumberField source="current_usage" label="Current Usage(GB)" sortable={false} />
        <NumberField source="usage_limit" sortable={false} />
        <TextField source="status" sortable={false} />
        {children}
        <StarlinkServiceLineSplitButton />
    </Datagrid>
);

StarlinkServiceLineRow.defaultProps = {
    basePath: '/starlink_service_lines'
};

export default StarlinkServiceLineRow;
